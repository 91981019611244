<template>
  <div class="wrapper">
    <Header />
    <div class="content">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'MainLayout',
  components: {
    Footer,
    Header,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  background: #131313;
}
@media (max-width: 992px) {
  .wrapper {
    flex-direction: column;
  }
}
</style>
